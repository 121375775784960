import logo from './logo.svg';
import './App.css';

import { Footer, Form, Testimonials, Hero, Brands, Services } from './components/index'

function App() {
  return (
    <div className="App">
     <Hero/>
     <Brands/>
     <Services/>
     <Testimonials/>
     <Form/>
     <Footer/>
    </div>
  );
}

export default App;
